<template>
  <div class="financial">
    <!-- 顶部导航栏 -->
    <topNav></topNav>
    <!-- 顶部导航栏 -->
    <!-- 背景开始 -->
    <div class="top-bg">
      <div class="top-bg-content">
        <div class="top-content-bannar">
          <div class="top-bannar-vis">
            <div>全场景金融营销解决方案</div>
            <div>
              用多种营销形式，接入衣食住行等生活场景，加速金融机构数字化转型
            </div>
          </div>
        </div>
      </div>
      <div class="top-bg-content-phone">
        <div class="top-content-bannar">
          <div class="top-bannar-vis">
            <div>智慧金融解决方案服务商</div>
            <div>
              互联网+科技，让金融更开放
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 背景结束 -->

    <!-- 部分1开始 -->
    <div class="section-1">
      <h2>金融机构需求</h2>
      <p class="title">以服务为动力，以解决企业难题为目标</p>
      <ul class="section-box">
        <li
          class="item move-1"
          v-for="(v, i) in financialList.financiaIcnList2"
          :key="i"
        >
          <img class="icn" :src="v.icn" />
          <p class="item-title">{{ v.title }}</p>
          <p class="subtitle">
            {{ v.subtitle }}
          </p>
        </li>
      </ul>
    </div>
    <!-- ----------移动开始 -->
    <div class="finane">
      <h2>番茄金融</h2>
      <p class="content">
        深耕金融行业10余年，为金融机构提供安全、稳定、可靠的，深入场景式的权益解决方案，快速实现业务互动，演绎线上线下新融合，助力银行、保险、证券等数字化转型升级。
      </p>
    </div>
    <!-- ----------移动结束 -->
    <!-- 部分1结束 -->

    <!-- 部分2开始 -->
    <div class="section-2">
      <div class="display-area">
        <ul class="area-detail">
          <li
            v-for="(v, i) in financialList.listsub"
            :key="i"
            v-show="v.id == financialList.current + 1"
          >
            <h2 class="title">{{ v.detailTitle }}</h2>
            <p class="p1">{{ v.p1 }}</p>
            <div class="area-border"></div>
            <p class="p2">
              {{ v.p2 }}
            </p>
          </li>
        </ul>
        <div class="banner">
          <div class="bannerBox">
            <ul class="boxBorder">
              <li
                class="bannerImg"
                :class="{
                  bannerAn: financialList.current == i,
                }"
                v-for="(v, i) in financialList.listsub"
                :key="i"
              >
                <img
                  :src="v.bannerImg"
                  v-show="v.id == financialList.current + 1"
                  alt=""
                />
              </li>
            </ul>
          </div>
        </div>
        <div class="tab-box">
          <ul class="banner-tab" ref="bannerTab">
            <li
              class="tab-item"
              :class="{
                'item-select': financialList.current == i - 1 && i != 0,
              }"
              @click="tabBanner(i)"
              v-for="(v, i) in financialList.list"
              :key="i"
            >
              <div class="item-title">{{ v.title }}</div>
              <p class="item-message">
                {{ v.message }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- ----------移动开始 -->
    <div class="phone-2">
      <h2>番茄金融赋能的行业场景</h2>
      <ul>
        <li class="sceneBox" v-for="(v, i) in financialList.sceneList" :key="i">
          <img :src="v.img" alt="" />
        </li>
      </ul>
    </div>
    <!-- ----------移动结束 -->
    <!-- 部分2结束 -->

    <!-- 部分3开始 -->
    <div class="section-3">
      <ul class="box">
        <li
          class="item"
          :class="scrollTop ? 'move' : ''"
          v-for="(v, i) in financialList.financiaIcnList"
          :key="i"
        >
          <img :src="v.icn" alt="" />
          <p class="title">{{v.p}}</p>
        </li>
      </ul>
    </div>
    <!-- ----------移动开始 -->
    <div class="phone-3">
      <h2>我们的优势</h2>
      <ul>
        <li
          class="advantageBox"
          v-for="(v, i) in financialList.sceneList"
          :key="i"
        >
          <div class="advantageLogo"><img :src="v.logo" alt="" /></div>
          <p class="title">{{ v.title }}</p>
          <p class="text">{{ v.text }}</p>
        </li>
      </ul>
    </div>
    <!-- ----------移动结束 -->
    <!-- 部分3结束 -->

    <!-- 部分4开始 -->
    <div class="section-4">
      <h2>来自上千家金融机构的信任</h2>
      <p class="subtitle">驱动数字金融发展，建设共赢生态圈</p>
      <div class="box">
        <ul class="wrap">
          <li
            class="wrap-title"
            :class="{ active: i + 1 == financialList.wrapNum }"
            v-for="(v, i) in financialList.wrapList"
            :key="i"
            @click="wrapTab(i)"
          >
            <p>{{ v.name }}</p>
          </li>
        </ul>
        <ul class="content">
          <li
            class="content-info"
            v-for="(v, i) in financialList.wrapListDetail"
            v-show="v.id == financialList.wrapNum"
            :key="i"
          >
            <div class="info-left">
              <img class="info-logo" :src="v.logo" alt="" />
              <p class="keyword">{{ v.keyword }}</p>
              <p class="detail">
                <span>{{ v.detailTitle }}</span>
                {{ v.detail }}
              </p>
              <a class="moreBtn">了解更多 ></a>
            </div>
            <img class="info-appImg" :src="v.appImg" alt="" />
          </li>
        </ul>
      </div>
    </div>
    <!-- 部分4结束 -->

    <Bottom></Bottom>
  </div>
</template>

<script scoped>
import "../assets/less/finamcial.less";
import financialList from "../assets/js/financial";
import topNav from "../components/topNav";
import Bottom from "../components/Bottom";
export default {
  data() {
    return {
      financialList,
      scrollTop: false,
    };
  },
  components: {
    topNav,
    Bottom,
  },
  // 刷新一次
  mounted: function() {
    window.addEventListener("scroll", this.windowScroll);

    if (location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      location.reload();
    }
  },
  methods: {
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 1700) {
        this.scrollTop = true;
      }
    },
    // 切换案例
    wrapTab(index) {
      this.financialList.wrapNum = index + 1;
    },
    // 上一页
    prev() {
      this.financialList.current--;
      let tab = this.$refs.bannerTab;
      if (tab.style.left == "" || tab.style.left == "0px") {
        tab.style.left = "-1104px";
        this.financialList.current = 3;
      }
      tab.style.left = "-" + this.financialList.current * 368 + "px";
    },
    // 下一页
    next() {
      this.financialList.current++;
      let tab = this.$refs.bannerTab;
      if (this.financialList.current == this.financialList.list.length - 3) {
        setTimeout(() => {
          this.financialList.current = 0;
        }, 500);
      }
      this.noLast = true;
      if (tab.style.left == "-1104px") {
        tab.style.left = "0px";
        this.financialList.current = 0;
      }
      tab.style.left = "-" + this.financialList.current * 368 + "px";
    },
    //自动播放
    autoSwitch() {
      this.timer = setInterval(() => {
        this.next();
      }, 6000);
    },
    // 切换tab
    tabBanner(i) {
      if (i == this.financialList.current) {
        this.prev();
        clearInterval(this.timer);
      } else {
        this.next();
        clearInterval(this.timer);
      }
      this.autoSwitch();
    },
    // 鼠标移入
    enter(index) {
      let hover = document.getElementsByClassName("brand-item");
      hover.forEach(function(list, i) {
        if (i == index) {
          hover[i].className = "brand-item hover";
        } else {
          hover[i].className = "brand-item";
        }
      });
    },
  },

  created() {
    // 自动播放
    this.autoSwitch();
  },
};
</script>
