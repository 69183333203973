export default {
    wrapList: [{
        id: 1,
        name: '中华保险'
    }],
    wrapListDetail: [{
            id: 1,
            logo: require('../image/financial-info-logo.png'),
            keyword: '关键词：抽奖活动、客户回馈、送福利',
            detailTitle: '案例详情：',
            detail: '中华保险为中华财险广东分公司倾情定制转盘抽奖活动，有效注册客户在活动期间内获得一次幸运大转盘抽奖机会，奖品涵括品牌手机、蓝牙耳机、现金红包等多项惊喜礼品。精准回馈新注册的客户，为客户解锁多重福利，进一步实现拉新、促活的活动目标。',
            appImg: require('../image/financial-info-img.png')
        },
    ],
    wrapNum: 1,
    list: [{
            title: "智能管理",
            message: "基于多年行业经验，创新技术为活动开展保驾护航，支持商品选择、数据分析等",
        },
        {
            title: "赋能品牌",
            message: "专业运营团队，覆盖海量用户，多渠道投放，支持业务快速成长",
        },
        {
            title: "多场景营销",
            message: "银行品牌宣传、理财基金产品推广、金融APP下载开户、公众号关注吸粉等需求",
        },
        {
            title: "官方合作",
            message: "对接官方权益资源，保障每一款权益产品的服务和质量，在业内拥有良好口碑",
        },
        {
            title: "智能管理",
            message: "基于多年行业经验，创新技术为活动开展保驾护航，支持商品选择、数据分析等",
        },
        {
            title: "赋能品牌",
            message: "专业运营团队，覆盖海量用户，多渠道投放，支持业务快速成长",
        },
        {
            title: "多场景营销",
            message: "银行品牌宣传、理财基金产品推广、金融APP下载开户、公众号关注吸粉等需求",
        },
        {
            title: "官方合作",
            message: "对接官方权益资源，保障每一款权益产品的服务和质量，在业内拥有良好口碑",
        },
    ],
    listsub: [{
        id: 1,
        bannerImg: require("../image/bannarImg1.png"),
        detailTitle: '高效运营，通达未来',
        p1: '服务即营销，促进用户转化及复购',
        p2: '7*24小时为用户解决问题，通过及时、专业的运营服务为银行减少客诉，激活存量用户，拉动新用户增量，让服务更贴近用户，提升营销效果。'
    }, {
        id: 2,
        bannerImg: require("../image/bannarImg2.png"),
        detailTitle: '按需定制，精准高效触达',
        p1: '玩转粉丝裂变，升级客户忠诚度',
        p2: '细分用户群体，借助微信公众号、小程序、APP等线上工具及易拉宝、展台、单页等线下工具，实现联动营销；强悍技术支持，持续更新活动形式，为银行增加赢利，提升品牌声浪。'
    }, {
        id: 3,
        bannerImg: require("../image/bannarImg3.png"),
        detailTitle: '一线权益产品，海量供应',
        p1: '1000+优质品牌入驻，标准API对接',
        p2: '严格把关每一个权益品牌，涵盖美食、影音、出行、阅读、健康、生活服务等领域，针对不同用户、不同应用场景、不同业务需求，为银行灵活搭配权益产品，达到用户满意、银行放心的目的。'
    }, {
        id: 4,
        bannerImg: require("../image/bannarImg4.png"),
        detailTitle: '专属管理平台，实时跟进',
        p1: '灵活控制成本，快速导出、分析数据',
        p2: '独立管理平台，操作简单方便，数据清晰明确，可根据需要的维度组合实时导出想要的结果，省时省力又省心，为银行提供一站式智能分析服务。'
    }],
    financiaIcnList: [{
        icn: require('../image/financialIcon1-1.png'),
        p: 'H5创意营销体系'
    }, {
        icn: require('../image/financialIcon1-2.png'),
        p: 'BI商业智能系统'
    }, {
        icn: require('../image/financialIcon1-3.png'),
        p: '优质资源整合能力'
    }, {
        icn: require('../image/financialIcon1-4.png'),
        p: '全媒体品宣服务'
    }, {
        icn: require('../image/financialIcon1-5.png'),
        p: '覆盖1亿+用户'
    }, {
        icn: require('../image/financialIcon1-6.png'),
        p: '7*24小时专业客服'
    }],
    financiaIcnList2: [{
        title: '丰富营销活动形式',
        subtitle: '金融机构营销活动形式单一，重合度高，对用户吸引力小，无法满足不断变化的用户需求。',
        icn: require('../image/financialIcn.png')
    }, {
        title: '控制技术研发成本',
        subtitle: '金融机构面临研发成本过高的问题，难以控制预算，在实现多场景营销上有一定困难。',
        icn: require('../image/financialIcn2.png')
    }, {
        title: '提升用户体验及口碑',
        subtitle: '金融机构用户繁杂且数据不共享，准确性低，无法快速灵活获取用户数据进行及时分析。',
        icn: require('../image/financialIcn3.png')
    }],
    current: 0,
    sceneList: [{
        img: require('../image/financial/scene-1.png'),
        logo: require('../image/financial/logo-1.png'),
        title: '产品',
        text: '完备的权益产品体系'
    }, {
        img: require('../image/financial/scene-2.png'),
        logo: require('../image/financial/logo-2.png'),
        title: '营销',
        text: '创意互动的智慧营销工具'
    }, {
        img: require('../image/financial/scene-3.png'),
        logo: require('../image/financial/logo-3.png'),
        title: '风控',
        text: '基于大数据的风控模型'
    }, {
        img: require('../image/financial/scene-4.png'),
        logo: require('../image/financial/logo-4.png'),
        title: '开放',
        text: '共创共赢的价值观与商业观'
    }]
}